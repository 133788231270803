.home-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100vw;
  height: 100%;
}

.info1{
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.info1 img{
  width: 280px;
  margin: 0 50px 0 0;
}

.info2{
  /* border: 1px solid blue; */
  width: 60vw;
}

.info-texto{
  width: 550px;
  /* height: 203px; */
}

.info-redes{
  /*border: 1px solid yellow;*/
  width: 550px;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}
.iconoRedes{
  /*border: 1px solid white;*/
  width: 35px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .info1 img{
    width: 250px;
    margin: 0 30px 0 0;
  }
  .info-texto {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .info1 {
    justify-content: center;
  }
  
  .info1 img{
    width: 200px;
    margin: 0 0 0 0;
  }
  .info-texto {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .home-content {
    flex-direction: column;
  }

  .info1, .info2 {
    width: 100%;
  }

  .info-texto {
    width: 70%;
  }

  .info2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .info-redes {
    justify-content: center;
  }

  .info2 .title,
  .info2 .subtitle {
    text-align: center;
  }

  .iconoRedes:last-of-type{
    margin-right: 0;
  }
}