.project-section-container {
  height: 100%;
  margin: 40px 0 0 40px;
  justify-content: flex-start;
}

.projects-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.project {
  margin: 0 3rem 3rem 0;
  position: relative;
  cursor: pointer;
}

.project:hover .project-detail,
.project-detail.isTouched {
  opacity: 1;
}

.project-detail {
  opacity: 0;
  transition: 0.2s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
}

.project-title {
  font-family: 'Tasa Orbiter', sans-serif;
  color: black;
  font-size: 16px;
  margin: 0;
  font-weight: 800;
  text-align: center;
  font-variation-settings: 'opsz' 60;
}

.project-description {
  font-family: 'Tasa Orbiter', sans-serif;
  color: black;
  font-size: 16px;
  margin: 0;
  width: 70%;
  text-align: center;
}

.project-img {
  border-radius: 16px;
  width: 400px;
}

.project.title {
  text-align: center;
  margin: 0 0 2rem 0;
}

.project-subtitle {
  color: white;
  font-style: italic;
  font-size: 22px;
  margin: 10px 0 20px 0;
  letter-spacing: 2px;
  text-align: center !important;
  width: 50vw !important;
}

.project-detail-container {
  height: 100%;
  width: 100vw;
  padding: 40px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.project-detail-description {
  text-align: left;
  width: 80vw;
}

.project-large-image-container {
  width: 60vw;
  margin: 1rem 0 2.25rem 0;
}

.project-square-image-container {
  width: 30vw;
  margin: 1rem 0 2.25rem 0;
}

.project-image {
  width: 100%;
  border-radius: 8px;
}

.project-small-img-container {
  justify-content: space-around;
  width: 60vw;
  margin: 1rem 0 2.25rem 0;
}

.project-image-small {
  width: 320px;
  border-radius: 8px;
}

.vimeo-embed-container {
  width: 60vw;
  margin: 1rem 0 2.25rem 0;
}

.vimeo-iframe{
  width: 100%;
  height: calc(60vw/1.77);
}

@media screen and (max-width: 1140px) {
  .project-img {
    width: 350px;
  }

  .project-image-small {
    width: 20vw;
  }
}

@media screen and (max-width: 1020px) {
  .project-img {
    width: 330px;
  }
}

@media screen and (max-width: 970px) {
  .project-img {
    width: 300px;
  }
}

@media screen and (max-width: 900px) {
  .project-img {
    width: 280px;
  }
}

@media screen and (max-width: 810px) {
  .project-section-container {
    width: 80%;
    margin: 2rem auto;
  }

  .project-img {
    width: 280px;
  }

  .projects-container {
    justify-content: space-between;
  }

  .project {
    margin: 0 0 2rem 0;
  }

  .project-description {
    font-size: 14px;
  }

  .project-large-image-container,
  .project-small-img-container,
  .project-square-image-container,
  .vimeo-embed-container,
  iframe {
    width: 80vw;
    /* height: 300px; */
  }

  .vimeo-iframe{
    height: calc(80vw/1.77);
  }

  .project-image-small {
    width: 30vw;
  }
}

@media screen and (max-width: 750px) {
  .project-img {
    width: 250px;
  }

}

@media screen and (max-width: 670px) {
  .project-img {
    width: 320px;
  }
  .projects-container {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .project-img {
    width: 280px;
  }
}
