@font-face {
  font-family: 'Ubuntu Mono';
  src: local('Ubuntu Mono'), url(./fonts/UbuntuMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Tasa Explorer';
  src: local('Tasa Explorer'), url(./fonts/TASAExplorerVF.woff2) format('woff2');
}

@font-face {
  font-family: 'Tasa Orbiter';
  src: local('Tasa Orbiter'), url(./fonts/TASAOrbiterVF.woff2) format('woff2');
}

body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 26, 0.897);
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

a{
  text-decoration: none;
  color: white;
  outline: none;
  /* font-size: 18px; */
  transition: .2s; 
}

path{
  transition: .2s;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.title{
  font-family: "Tasa Explorer";
  color: white;
  font-size: 32px;
  font-weight: 700;
  margin: 10px 0 10px 0;
  /* line-height: 44.6833px; */
  letter-spacing: 0.48px;
}

.subtitle{
  color: white;
  font-size: 22px;
  margin: 10px 0 20px 0;
  font-family: "Tasa Orbiter";
  line-height: 36.5633px;
  letter-spacing: 2px;
  font-weight: 400;
  font-variation-settings: 'opsz' 60;
}

.paragraph{
  color: white;
  font-size: 16px;
  margin: 0 0 20px 0;
  font-family: "Tasa Orbiter";
  line-height: 24px;
  letter-spacing: 0.48px;
  font-weight: 400;
  font-variation-settings: 'opsz' 8;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.react-body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content{
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  overflow: hidden;
}

.btn{
  padding: 12px;
  color: white;
  font-size: 18px;
  text-align: center;
  background: rgba(227,75,48, .8);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.btn:hover{
  background: rgba(227,75,48, 1);;
}

.btn:active{
  transform: scale(.95);
}

.soon{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.soon h1{
  font-size: 40px;
  color: white;
  font-family: "Tasa Orbiter";
}

.footer{
  /*border: 1px solid red;*/
  width: 100vw;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Tasa Orbiter', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.footer p{
  margin: auto;
}


figure{
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .paragraph {
    font-size: 16px;
    margin: 0 0 14px 0;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 16px;
  }
  footer {
    font-size: 12px;
  }
  .paragraph {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .title {
    font-size: 18px;
  }
  .subtitle {
    font-size: 14px;
  }
}